.feedBackContainer {
  padding: 88px 0 0 0;
}

.card {
  border-radius: 12px;
  border: 1px solid #EAECF0;
  background: rgba(255, 255, 255, 0.60);
  backdrop-filter: blur(12px);
  padding: 32px 28px;
  min-width: calc(50% - 10px);
}

.cardTitle {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}


.cardTitle img {
  object-fit: contain;
}

.listCard {
  display: flex;
  gap: 20px;
  max-width: calc(1010px - 4px);
  margin: auto;
  transition: 0.5s all;
}

.top {
  display: flex;
  gap: 16px;
}

.des {
  color: var(--text-text-500, #1D2939);
  /* Text sm/Medium */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-top: 24px;
}

.top div div:first-child {
  color: var(--text-text-500, #1D2939);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.top div div:last-child {
  color: var(--text-text-400, #667085);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; 
}

.arrowRight {
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 3;
}

.arrowLeft {
  position: absolute;
  top: 50%;
  left: 0px;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 3;
}

@media screen and (max-width: 1100px) {
  .card {
    min-width: 100%;
  }

  .arrowRight {
    right: -10px;
  }
  
  .arrowLeft {
    left: -10px;
  }

  .feedBackContainer {
    padding: 50px 0 0 0;
  }
}

@media screen and (max-width: 650px) {
  .cardTitle {
    flex-direction: column;
    gap: 24px;
  }

  .cardTitle img {
    margin: auto;
  }
}
