.adminTaskContainer {
  padding: 150px 0;
  position: relative;
}

.circle {
  position: absolute;
  width: 120vw;
  max-width: 1750px;
  height: 100%;
  max-height: 327px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) translateY(82%) scale(1);
  transition: 0.5s all;
  transform-origin: center;
}

.title {
  color: var(--text-text-500, #1D2939);
  text-align: center;

  /* Display md/Bold */
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px; /* 122.222% */
  letter-spacing: -0.72px;
  max-width: 620px;
  margin: auto auto 58px auto;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-bottom: 119px;
}

.buttons div {
  padding: 10px 16px;
  color: var(--text-text-400, #667085);
  /* Text sm/Medium */
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  cursor: pointer;
  border-radius: 1000px;
  border: 1px solid transparent;
}

.buttons div:hover {
  padding: 10px 16px;
  background: var(--background-background-3, #F9FAFB);
}

.buttons .active {
  color: var(--text-text-500, #1D2939);
  border: 1px solid var(--border-border-500, #D0D5DD);
  background: var(--base-white, #FFF);
}

.listTask {
  width: 907px;
  height: 420px;
  position: relative;
  margin: auto;
}

.taskItem {
  border-radius: 36px;
  padding: 32px;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  flex-shrink: 0;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr;
  opacity: 0;
  transition: all 0.5s;
  z-index: 2;
}

.listTask span:nth-child(1) {
  background: #0D715F;
}

.listTask span:nth-child(2) {
  background: #FFBC0F;
  transform: rotate(-15deg) translateY(100%);
}

.listTask span:nth-child(3) {
  background: #7A08ED;
  transform: rotate(15deg) translateY(100%);
}

.taskItem section {
  display: flex;
  gap: 24px;
  flex-direction: column;
  justify-content: flex-end;
}

.img {
  position: absolute;
  right: -75px;
  bottom: -2px;
  transition: all 0.5s;
  opacity: 1;
}

.imgAnimate {
  opacity: 0;
}

.taskTitle {
  color: var(--base-white, #FFF);
  /* Display sm/Medium */
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 38px; /* 126.667% */
}

.taskDesc {
  color: var(--base-white, #FFF);
  /* Text lg/regular */
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 155.556% */
}

.taskActive {
  opacity: 1;
  transform: rotate(0deg) translateY(0%) !important;
}

.listTask2 span:nth-child(1) {
  opacity: 0.6;
  transform: scale(0.95) translateY(-10%); 
}

.listTask2 span:nth-child(1) .img {
  opacity: 0;
}

.listTask2 .circle {
  transform: translateX(-50%) translateY(82%) scale(1.25);
}

.listTask3 .circle {
  transform: translateX(-50%) translateY(82%) scale(0.9);
}

.listTask3 span:nth-child(1) {
  opacity: 0.2;
  transform: scale(0.9) translateY(-20%);
}

.listTask3 span:nth-child(1) .img {
  opacity: 0;
}

.listTask3 span:nth-child(2) {
  opacity: 0.6;
  transform: scale(0.95) translateY(-10%); 
}

.listTask3 span:nth-child(2) .img {
  opacity: 0;
}

@media screen and (max-width: 1100px) {
  .adminTaskContainer {
    padding: 60px 0;
  }

  .listTask {
    width: auto;
    height: 320px;
    position: relative;
    margin: auto;
  }

  .taskItem {
    align-items: center;
    gap: 30px;
  }
  
  .taskTitle {
    color: var(--base-white, #FFF);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px; /* 150% */
  }

  .taskDesc {
    color: var(--base-white, #FFF);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }

  .img {
    transform: scale(0.65);
    bottom: unset;
  }

  .title {
    color: #1D2939;
    text-align: center;
    /* Text md/Bold */
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
    margin-bottom: 32px;
  }

  .buttons {
    margin-bottom: 60px;
    gap: 8px;
  }

  .buttons div {
    padding: 8px !important;
    color: var(--text-text-500, #1D2939);
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 180% */
  }

}

@media screen and (max-width: 768px) {
  .img {
    display: none;
  }
  
  .circle {
    transform: translateX(-50%) translateY(100%) scale(1.5);
  }

  .buttons {
    margin-bottom: 50px;
    gap: 0px;
  }

  .listTask {
    height: 200px;
  }

  .taskItem {
    grid-template-columns: 1fr;
    padding: 16px;
    border-radius: 24px;
  }

  .taskActive section {
    gap: 12px;
  }
}

@media screen and (max-width: 600px) {
  .listTask {
    height: 180px;
  }

  .taskItem {
    align-items: end;
  }
}