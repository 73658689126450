.splashContainer {
  height: 100vh;
  width: 100%;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  background-color: #FCFAF4;
}

.splashContainerAnimate {
  background-color: transparent;
}

.body {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.circle {
  bottom: 0;
  opacity: 1;
  transform-origin: center 25%;
  transform: translateX(-51%) scale(1);
  position: absolute;
  left: 50%;
  width: 1753px;
  height: 732px;
  background-image: url(/v3/splash/loading.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto;
  top: 20%;
  transition: all 2s;
}

.circleAnimate {
  transform: translateX(-50%) scale(0);
  opacity: 0;
}

.fullLogo {
  bottom: 20%;
  display: flex;
  align-items: center;
  gap: 42px;
  position: relative;
  width: 325px;
  height: 292px;
  transform: translateY(40%) scale(0.8);
}

@media screen and (max-height: 660px) {
  .fullLogo {
    transform: translateY(32%) scale(0.8);
  }
}

.fullLogo {
  display: flex;
  align-items: baseline;
  gap: 2px;
}

/* Animation for Logo */

.fullLogo .logo {
  opacity: 0;
  bottom: -100px;
  left: 10px;
  transition: all 1.5s;
  position: absolute;
}

.fullLogo .z {
  height: 77px;
  width: 71px;
  position: absolute;
  transition: all 1.5s;
  bottom: 0px;
  left: calc(50% - 80px);
  transform: translateX(-50%);
}

.fullLogo .e {
  height: 76px;
  width: 86px;
  position: absolute;
  transition: all 1.5s;
  bottom: 146px;
  left: calc(50% - 8px);
  transform: translateX(-50%);
}


.fullLogo .n {
  height: 80px;
  width: 93px;
  position: absolute;
  transition: all 1.5s;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
}

.fullLogo .c {
  height: 47px;
  width: 36px;
  position: absolute;
  transition: all 1.5s;
  bottom: 238px;
  left: calc(50% - 6px);
  transform: translateX(-50%);
}

.fullLogo .c2 {
  height: 53px;
  height: 42px;
  position: absolute;
  transition: all 1.5s;
  opacity: 0;
  left: calc(100% + 75px);
  bottom: 50%;
  transform: translateX(-50%);
}

.fullLogo .l {
  height: 64px;
  width: 18px;
  position: absolute;
  transition: all 1.5s;
  bottom: 198px;
  left: calc(50% - 8px);
  transform: translateX(-50%) rotate(-90deg);
}

.fullLogo .a {
  height: 86px;
  width: 99px;
  position: absolute;
  transition: all 1.5s;
  bottom: 70px;
  right: 75px;
}

.fullLogo .s {
  height: 79px;
  width: 84px;
  position: absolute;
  transition: all 1.5s;
  bottom: 73px;
  left: 60px;
}

.fullLogo .s:last-child {
  height: 77px;
  width: 82px;
  position: absolute;
  transition: all 1.5s;
  bottom: 0px;
  left: calc(50% + 82px);
  transform: translateX(-50%);
}

.fullLogo .left {
  position: absolute;
  transition: all 1.5s;
  bottom: -4px;
  left: 10px;
}

.fullLogo .right {
  position: absolute;
  transition: all 1.5s;
  bottom: -4px;
  right: -5px;
  opacity: 1;
}

.fullLogo .shadow {
  position: absolute;
  transition: all 1.5s;
  top: calc(100% - 2px);
  opacity: 1;
  width: 452px;
  height: 204px;
  left: 10px;
}


.fullLogoAnimate .logo {
  opacity: 1;
  bottom: 8px;
  left: calc(-25px);
  transform: translateX(-100%);
}

.fullLogoAnimate .z {
  height: 64px;
  width: 59px;
  bottom: 60px;
  left: calc(50% - 124px);
}

.fullLogoAnimate .e {
  height: 52px;
  width: 58px;
  bottom: 60px;
  left: calc(50% - 62px);
}

.fullLogoAnimate .n {
  height: 50px;
  width: 58px;
  bottom: 60px;
}

.fullLogoAnimate .c {
  left: 0px;
  bottom: calc(50% - 25px);
  opacity: 0;
}

.fullLogoAnimate .c2 {
  height: 53px;
  width: 40px;
  bottom: 60px;
  left: calc(50% + 56px);
  opacity: 1;
}

.fullLogoAnimate .l {
  bottom: 60px;
  left: calc(50% + 91px);
  transform: translateX(-50%) rotate(0deg);
}

.fullLogoAnimate .a {
  height: 52px;
  width: 69px;
  bottom: 60px;
  right: calc(50% - 170px);
}

.fullLogoAnimate .s {
  height: 48px;
  width: 51px;
  bottom: 60px;
  left: calc(50% + 172px);
}

.fullLogoAnimate .s:last-child {
  height: 48px;
  width: 51px;
  bottom: 60px;
  left: calc(50% + 253px);
}

.fullLogoAnimate .left {
  bottom: 37%;
  left: -46%;
  opacity: 0;
}

.fullLogoAnimate .right {
  bottom: 40%;
  right: 50%;
  opacity: 0;
}

.fullLogoAnimate .shadow {
  opacity: 0;
}

.backgroundCircle {
  background: #0D715F;
  border-radius: 50%;
  position: fixed;
  width: 10px;
  height: 10px;
  bottom: 10%;
  right: 10%;
  transform: scale(0);
  transform-origin: 50% 50%;
  z-index: 99;
  transition: transform 1.75s, opacity 0.5s;
  opacity: 0;
}

.backgroundCircleAnimate {
  transform: scale(1000);
  opacity: 1;
}

@media screen and (max-width: 1200px) {
  .fullLogo {
    transform: translateY(25%) scale(0.5);
  }
}